import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Elements
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import ButtonPrimaryTransparent from 'components/elements/Buttons/ButtonPrimaryTransparent'

// Images
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimarySmall'
import ParseContent from 'components/shared/ParseContent'

const Wrapper = styled.section``

const PostsWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  @media (min-width: 992px) {
    box-shadow: 1px 1px 12px 0px #404040;
  }
`

const Content = styled(ParseContent)`
  & h1 > span:first-child,
  & h2 > span:first-child,
  & h3 > span:first-child,
  & h4 > span:first-child,
  & h5 > span:first-child,
  & h6 > span:first-child {
    font-family: ${({ theme }) => theme.font.family.primary} !important;
    font-weight: ${({ theme }) => theme.font.weight.ultraLight} !important;
  }

  & h2 {
    font-size: ${({ theme }) => theme.font.size.giga};
    line-height: 70px;
    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.huge} !important;
    }
  }

  border-bottom: 2px solid ${({ theme }) => theme.color.secondary};
`

interface HighlightedVacanciesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_HighlightedPosts
}

const HighlightedVacancies: React.FC<HighlightedVacanciesProps> = ({
  fields,
}) => (
  <Wrapper className="py-5 mb-5 ">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <PostsWrapper className="py-xl-4 px-lg-0 px-5">
            <div className="py-5 row justify-content-center">
              <div className="col-lg-10">
                <Content
                  content={fields.description}
                  className="mb-lg-4 pb-5"
                />
              </div>
              <Blog fields={fields} />
            </div>
          </PostsWrapper>
        </div>
      </div>
    </div>
  </Wrapper>
)

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_HighlightedPosts
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpVacancy,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedVacanciesQueryQuery>(graphql`
    query highlightedVacanciesQuery {
      allWpVacancy(sort: { order: DESC, fields: date }) {
        edges {
          node {
            id
            databaseId
            title
            uri
            vacancydetail {
              info {
                icons {
                  iconcontrast {
                    localFile {
                      publicURL
                    }
                  }
                  iconblank {
                    localFile {
                      publicURL
                    }
                  }
                }
                title
              }
              shortdescription
              fieldGroupName
            }
          }
        }
      }
    }
  `)

  const posts: unknown = allWpVacancy.edges

  return (
    <BlogBink
      posts={posts as BlogBinkPosts}
      id="highlighted-Vacancies"
      showIds={fields?.relationvacancy?.map(
        ({ databaseId }: any) => databaseId
      )}
      limit={Infinity}
    >
      <BlogGrid fields={fields} />
    </BlogBink>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: any
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <>
      {blogBink.posts.map((post) => {
        const { node }: any = post

        return (
          <div key={post.node.id} className="col-lg-10 d-flex flex-grow- 1mt-5">
            <BlogGridPost node={node} blogFields={fields} />
          </div>
        )
      })}
      <div className="d-flex justify-content-center mt-5 pt-lg-4">
        <ButtonPrimaryTransparent to={fields?.link?.url || '/'}>
          {fields?.link?.title}
        </ButtonPrimaryTransparent>
      </div>
    </>
  )
}

const PostWrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.color.secondary};

  & svg {
    min-height: 25px;
    min-width: 25px;
  }

  & h3 {
    font-size: 20px !important;
  }
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  & button {
    padding: 0 30px;
  }
`

const SvgWrapper = styled.div`
  min-width: 40px;
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: any
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const { vacancydetail } = node

  return (
    <div className="d-flex flex-lg-column flex-row w-100">
      <PostWrapper className="px-lg-2 py-lg-4 px-1 py-4">
        <div className="d-flex flex-column flex-sm-row justify-content-between mb-4">
          <h3>{node.title}</h3>
          <StyledButtonPrimary to={node.uri}>
            Bekijk vacature
          </StyledButtonPrimary>
        </div>
        <div className="row flex-column flex-lg-row justify-content-between">
          {vacancydetail?.info?.reverse().map((info: any, index: number) => (
            <div
              className="col-lg-3 d-flex align-items-center mb-3 mb-lg-0"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <SvgWrapper>
                <Plaatjie
                  image={info?.icons.iconblank}
                  alt={info?.title || 'Icoon'}
                />
              </SvgWrapper>
              <span>{info?.title}</span>
            </div>
          ))}
        </div>
      </PostWrapper>
    </div>
  )
}

export default HighlightedVacancies
